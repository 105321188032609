(() => {
  const cardJs = document.querySelector('.card-js');
  const cardCss = document.querySelector('.card-css');
  const cardNode = document.querySelector('.card-nodejs');
  const cardHtml = document.querySelector('.card-html');
  const cardReact = document.querySelector('.card-react');
  const cardRedux = document.querySelector('.card-redux');

  cardJs.addEventListener('click', function (e) {
    const qwe = document.querySelector('.card.is-flipped');
    if (qwe && qwe !== cardJs) qwe.classList.remove('is-flipped');
    cardJs.classList.toggle('is-flipped');
  });
  cardCss.addEventListener('click', function () {
    const qwe = document.querySelector('.card.is-flipped');
    if (qwe && qwe !== cardCss) qwe.classList.remove('is-flipped');
    cardCss.classList.toggle('is-flipped');
  });
  cardNode.addEventListener('click', function () {
    const qwe = document.querySelector('.card.is-flipped');
    if (qwe && qwe !== cardNode) qwe.classList.remove('is-flipped');
    cardNode.classList.toggle('is-flipped');
  });
  cardHtml.addEventListener('click', function () {
    const qwe = document.querySelector('.card.is-flipped');
    if (qwe && qwe !== cardHtml) qwe.classList.remove('is-flipped');
    cardHtml.classList.toggle('is-flipped');
  });
  cardReact.addEventListener('click', function () {
    const qwe = document.querySelector('.card.is-flipped');
    if (qwe && qwe !== cardReact) qwe.classList.remove('is-flipped');
    cardReact.classList.toggle('is-flipped');
  });
  cardRedux.addEventListener('click', function () {
    const qwe = document.querySelector('.card.is-flipped');
    if (qwe && qwe !== cardRedux) qwe.classList.remove('is-flipped');
    cardRedux.classList.toggle('is-flipped');
  });
})();
